<template>
    <main-layout>
        <template #content>
            <header-content :header-info="headerInfo" />
            <agreements />
        </template>
    </main-layout>
</template>

<script>
import MainLayout from '@/layouts/MainLayout.vue'
import HeaderContent from '@/components/HeaderContent';
import { documentsBreadcrumbsStepsPages } from '@/helpers/documents/breadcrumbsStepsList';
import Agreements from '@/components/common/Agreements';

export default {
    documentsBreadcrumbsStepsPages,
    name: 'WriterAgreement',
    components: {
        MainLayout,
        HeaderContent,
        Agreements
    },
    metaInfo: {
        title: 'Freelance writer agreement'
    },
    data() {
        return {
            headerInfo: {
                title: 'Freelance writer agreement',
                className: 'header-content__documents',
                steps: this.$options.documentsBreadcrumbsStepsPages
            }
        }
    }
}
</script>
